// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-js": () => import("./../../../src/pages/a-propos.js" /* webpackChunkName: "component---src-pages-a-propos-js" */),
  "component---src-pages-error-pages-401-js": () => import("./../../../src/pages/error_pages/401.js" /* webpackChunkName: "component---src-pages-error-pages-401-js" */),
  "component---src-pages-error-pages-404-js": () => import("./../../../src/pages/error_pages/404.js" /* webpackChunkName: "component---src-pages-error-pages-404-js" */),
  "component---src-pages-error-pages-500-js": () => import("./../../../src/pages/error_pages/500.js" /* webpackChunkName: "component---src-pages-error-pages-500-js" */),
  "component---src-pages-fun-button-index-js": () => import("./../../../src/pages/fun/button/index.js" /* webpackChunkName: "component---src-pages-fun-button-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

